import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BorderAllRounded } from "@mui/icons-material";
import { SnackbarProvider } from "notistack";

const theme = createTheme({
  palette: {
    primary: {
      main: "#013490",
    },
    secondary: {
      main: "#039fdd",
    },
    success: {
      main: "#1bcfb4",
    },
    error: {
      main: "#fe7c96",
      contrastText: "#FFF",
    },
    warning: {
      main: "#fed713",
    },
  },
  typography: {
    allVariants: {
      fontFamily: `"Lato", Helvetica, Arial, serif`,
    },
  },
  overrides: {
    input: {
      fontFamily: `"Lato", Helvetica, Arial, serif`,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontVariantCaps: "initial",
          fontWeight: "700",
          padding: "0.5rem 1.5rem",
          borderRadius: "0.358rem",
          textTransform: "initial",
        },
      },
    },
  },
});

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
// serviceWorker.unregister();
