export const endpointApiUrl = `https://api.fundacionsanjuanpablo.org.ec`;
// export const endpointApiUrl = `http://127.0.0.1:8000`;


export const endpointApi = {
  url: endpointApiUrl,
  endpoint: {
    user: `${endpointApiUrl}/user/`,
    login: `${endpointApiUrl}/login/`,
    person: `${endpointApiUrl}/person/`,
    province: `${endpointApiUrl}/province/`,
    city: `${endpointApiUrl}/city/`,
    parish: `${endpointApiUrl}/parish/`,
    data: `${endpointApiUrl}/data/`,
    admission: `${endpointApiUrl}/admission/`,
    therapy: `${endpointApiUrl}/therapy/`,
    diagnosis: `${endpointApiUrl}/diagnosis/`
  }
}

export const groups = {
  seller: 1,
  sales_coordinator: 2,
  approver: 3
}