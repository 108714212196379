import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "Candidate": "Candidate",
          "Legal representative": "Legal representative",
          "Document type": "Document type",
          "Document number": "Document number",
          "Name": "Name",
          "Last name": "Last name",
          "Gender": "Gender",
          "Email": "Email",
          "Basic information": "Basic information",
          "Birthdate": "Birthdate",
          "Civil status": "Civil status",
          "Contact information": "Contact information",
          "Landline": "Landline",
          "Cell phone": "Cell phone",
          "City": "City",
          "Address": "Address",
          "Working Information": "Working Information",
          "Economic activity": "Economic activity",
          "Company": "Company",
          "Position": "Position",
          "Monthly income": "Monthly income",
          "Save": "Save",
          "Interest": "Interest",
          "Type of disability": "Type of disability",
          "Administration": "Administration",
          "Peadmission": "Preadmission",
          "Egress": "Egress",
          "Request": "Request",

        }
      },
      es: {
        translations: {
          "Candidate": "Candidato",
          "Legal representative": "Representante legal",
          "Document type": "Tipo Documento",
          "Document number": "Numero documento",
          "Name": "Nombre",
          "Last name": "Apellido",
          "Gender": "Genero",
          "Email": "Correo",
          "Basic information": "Informacion basica",
          "Birthdate": "Fecha de nacimiento",
          "Civil status": "Estado Civil",
          "Contact information": "Informacion de contacto",
          "Landline": "Telefono Fijo",
          "Cell phone": "Celular",
          "City": "Ciudad",
          "Address": "Direccion",
          "Working Information": "Informacion laboral",
          "Economic activity": "Actividad economica",
          "Company": "Empresa",
          "Position": "Puesto",
          "Monthly income": "Ingresos mensuales",
          "Save": "Guardar",
          "Interest": "Interes",
          "Type of disability": "Type of disability",
          "Administration": "Administracion",
          "Preadmission": "Pre admisión",
          "Egress": "Egresos",
          "Request": "Solicitud",

          
        }
      }
    },
    lng: "es",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;